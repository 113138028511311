<div
  [class]="['panel_container', 'action-length-' + actions.length]"
  [style.paddingTop]="inlineActions ? 0 : '4px'"
>
  <ng-container *ngIf="actions && isSingleAction && singleAction">
    <ng-container *ixRequiresRoles="actions[0].actions[0].requiresRoles">
      <button
        *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(actions[0].actions[0].id, row)"
        mat-button
        [ixTest]="actions[0].actions[0].label"
        [id]="'action_' + singleAction.name + '__' + singleAction.id"
        [disabled]="singleAction.disabled"
        [color]="actions[0].actions[0].color ? actions[0].actions[0].color : 'default'"
        (click)="singleAction.onClick(this.row)"
      >
        {{ actions[0].actions[0].label }}
      </button>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="!isSingleAction && !inlineActions; else inlineActionsTemplate"
  >
    <!-- Material Design Icon Set -->
    <button
      *ngIf="iconName.startsWith('mdi-')"
      class="transparent-btn"
      [ixTest]="[entity?.conf?.title, 'options']"
      [attr.aria-label]="'Actionable Options' | translate"
      [matMenuTriggerFor]="appMenu"
      (click)="noPropagate($event)"
    >
      <ix-icon
        [id]="row[entity?.conf?.rowIdentifier || 'name'] + '_' + action + '_button'"
        [name]="iconName"
        [style.cursor]="'pointer'"
        [style.font-size]="'24px'"
      ></ix-icon>
    </button>

    <!-- Standard Material Icon Set -->
    <button
      *ngIf="!iconName.startsWith('mdi-')"
      class="transparent-btn"
      [ixTest]="[entity?.conf?.title, 'options']"
      [attr.aria-label]="'Actionable Options' | translate"
      [matMenuTriggerFor]="appMenu"
      (click)="noPropagate($event)"
    >
      <ix-icon
        [id]="row[entity?.conf?.rowIdentifier || 'name'] + '_' + action + '_button'"
        [name]="iconName"
        [style.cursor]="'pointer'"
      ></ix-icon>
    </button>

    <mat-menu #appMenu="matMenu">
      <span *ngIf="!groups; else groupMenu">
        <span
          *ngFor="let action of actions"
          [matTooltip]="action.matTooltip"
          [matTooltipDisabled]="!action.disabled"
          [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
        >
          <ng-container *ixRequiresRoles="action.requiresRoles">
            <button
              *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
              mat-menu-item
              [ixTest]="[entity?.conf?.title, 'options', action.label]"
              [id]="'action_button_' + action?.name + '__' + action.id"
              [disabled]="action.disabled"
              (click)="action.onClick(this.row)"
            >
              <span>{{ action?.label | translate }}</span>
            </button>
          </ng-container>
        </span>
      </span>
      <ng-template #groupMenu>
        <span *ngFor="let group of actions">
          <div
            *ngIf="group.actions && group.actions.length > 0"
            class="group-menu-header"
          >
            <mat-divider></mat-divider>
            <div class="title">
              <strong>{{ group?.title | translate }}</strong>
            </div>
          </div>
          <span
            *ngFor="let action of group.actions"
            [matTooltip]="action.matTooltip"
            [matTooltipDisabled]="!action.disabled"
            [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
          >
            <ng-container *ixRequiresRoles="action.requiresRoles">
              <button
                *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
                mat-menu-item
                [ixTest]="[entity?.conf?.title, 'options', action.label]"
                [id]="'action_button_' + action?.name + '__' + action.id"
                [disabled]="action.disabled"
                (click)="action.onClick(this.row)"
              >
                <span>{{ action?.label | translate }}</span>
              </button>
            </ng-container>
          </span>
        </span>
      </ng-template>
    </mat-menu>
  </ng-container>

  <ng-template #inlineActionsTemplate>
    <ng-container *ngFor="let action of actions">
      <ng-container *ixRequiresRoles="action.requiresRoles">
        <button
          *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
          mat-icon-button
          [ixTest]="[entity?.conf?.title, 'options', action.name]"
          [id]="'action_button_' + action?.name + '__' + action.id"
          [disabled]="action.disabled"
          [matTooltip]="action.label | translate"
          [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
          (click)="action.onClick(this.row)"
        >
          <ix-icon [name]="action.icon"></ix-icon>
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
