<div
  [class.not-top]="!isTop"
  [class.is-top]="isTop"
  [id]="componentInfo.id"
  [class]="['ix-' + componentInfo.id, 'ix-slide-in2-form']"
  [class.open]="isSlideInOpen"
  [class.wide]="wide"
>
  <div
    class="ix-slide-in2-body"
  >
    <ng-container #chainedBody></ng-container>
  </div>
</div>

<div
  [id]="componentInfo.id + '-background'"
  [class]="['ix-slide-in2-background', 'ix-' + componentInfo.id + '-background', 'ix-slide-in2-'+index+'-background']"
  [class.open]="isSlideInOpen"
  (click)="onBackdropClicked()"
></div>
