<section *ngIf="this.columns?.length" class="wrapper__details">
  <div
    *ngFor="let column of this.columns"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <h4>{{ column.name | translate }}:</h4>
    <div fxLayoutAlign="start center" fxLayoutGap="4px">
      <p>{{ getColumnValue(column) }}</p>
    </div>
  </div>
</section>

<mat-divider *ngIf="this.columns?.length"></mat-divider>

<div
  *ngIf="parent.hasActions"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  class="wrapper__action-buttons"
>
  <div class="wrap-buttons-container">
    <ng-container *ngFor="let action of actions">
      <ng-container *ixRequiresRoles="action.requiresRoles">
        <button
          *ngIf="!parent.conf.isActionVisible || parent.conf.isActionVisible.bind(parent.conf)(action.id, this.config)"
          mat-button
          [ixTest]="[config.name | cast, action.label]"
          [id]="'action_button_' + action?.name + '__' + action.id"
          [disabled]="action.disabled"
          (click)="action.onClick(this.config)"
        >
          <div fxLayoutAlign="center center" fxLayoutGap="8px">
            <ix-icon [name]="action.icon"></ix-icon>
            <p
              [matTooltip]="action.matTooltip"
              [matTooltipDisabled]="!action.disabled"
              [matTooltipPosition]="action.ttposition ? action.ttposition : 'below'"
            >
              {{ action.label | translate }}
            </p>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
