<mat-checkbox
  color="primary"
  [checked]="value"
  [required]="required"
  [disabled]="isDisabled"
  [ixTest]="controlDirective.name"
  (change)="onCheckboxChanged($event)"
  (blur)="onTouch()"
>
  {{ label }}
  <span *ngIf="required" class="required">*</span>
</mat-checkbox>

<ix-tooltip
  *ngIf="tooltip"
  class="tooltip"
  [header]="label"
  [message]="tooltip"
></ix-tooltip>

<ix-warning *ngIf="warning" [message]="warning"></ix-warning>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
