<ix-fake-progress-bar [loading]="isLoading"></ix-fake-progress-bar>

<h1 mat-dialog-title>{{ 'Start {service} Service' | translate: { service: serviceHumanName } }}</h1>
<div mat-dialog-content>
  <p class="description">
    {{ '{service} Service is not currently running. Start the service now?' | translate: { service: serviceHumanName } }}
  </p>

  <ix-slide-toggle
    [formControl]="startAutomaticallyControl"
    [label]="'Enable this service to start automatically.' | translate"
  ></ix-slide-toggle>
</div>

<ix-form-actions mat-dialog-actions>
  <button
    mat-button
    ixTest="do-not-start"
    [disabled]="isLoading"
    (click)="onCancel()"
  >{{ 'No' | translate }}</button>

  <button
    mat-button
    color="primary"
    ixTest="enable-service"
    [disabled]="isLoading"
    (click)="onSubmit()"
  >{{ 'Start' | translate }}</button>
</ix-form-actions>
